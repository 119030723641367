import { useAuthWrapper } from './useAuthWrapper';

export const useUserAccount = () => {
  const authWrapper = useAuthWrapper();
  if ( authWrapper?.userAccount ) {
    return authWrapper.userAccount;
  }

  return undefined;
}
